.nav-bar {
    background: #181818;
    width: 100px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;
        img {
            display: block;
            margin: 8px auto;
            width: 48px;
            height: auto;
            &.sub-logo {
                width: 75px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 300px;
        top: 40%;
        margin-top: -120px;
        width: 100%;
        overflow-y: scroll;

        a {
            font-size: 32px;
            color: #4d4d4e;
            display: block;
            line-height: 75px;
            height: 75px;
            position: relative;
            text-decoration: none;
        
            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #ffd700;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 16px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &:after {
                    content: 'HOME';
                }
            }
        }

        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }

        a.project-link {
            &:after {
                content: 'PROJECTS';
            }
        }
        
        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }

        a.active {
            svg {
                color: #ffd700;
            }
        }
    }
    
    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;
    
        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 32px;
                line-height: 50px;
    
                &:hover svg {
                    color: #ffd700;
                }
            }
        }
    }
}

.hamburger-icon, .close-icon{
    display: none;
}

@media screen and (max-width: 1200px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;

        ul, nav, .logo, .close-icon {
            display: none;
        }
    
        nav {
            width: 100%;
            height: 60%;
            background: #181818;
            top: 25%;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;
    
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            a::after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }
    
            a svg {
                opacity: 1 !important; 
            }
    
            &.mobile-show {
                display: block;
            }
        }

        ul {
            width: 100%;
            height: 20%;
            background: #181818;
            bottom: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;
    
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            a::after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }
    
            a svg {
                opacity: 1 !important; 
            }
    
            &.mobile-show {
                display: block;
            }
        }

        .logo {
            width: 100%;
            height: 25%;
            background: #181818;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;
    
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            a::after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }
    
            a svg {
                opacity: 1 !important; 
            }
    
            &.mobile-show {
                display: block;
            }
        }
    
        .hamburger-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
        }

        .close-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;

            &.mobile-show {
                display: block;
            }
        }
    }
}

@media screen and (max-height: 600px){
    .nav-bar {
        ul {
            justify-content: center;
            align-items: center;
            gap: 5%;

            &.mobile-show {
                display: flex;
            }
        }
    }
            
}