.project-page {
    left: 50px;
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    height: 90%;
    vertical-align: middle;

    h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        margin-top: 10px;
        position: relative;
        margin-bottom: 40px;
        left: 20px;

        &:before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            opacity: 0.6;
            line-height: 20px;
            letter-spacing: 3px;
            animation: fadeIn 5s 1.7s backwards;
        }

        &:after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            line-height: 20px;
            position: absolute;
            left: -30px;
            bottom: -20px;
            margin-left: 20px;
            opacity: 0.6;
            letter-spacing: 3px;
            animation: fadeIn 5s 1.7s backwards;
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }

    .videos-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .project-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        h1 {
            margin-left: 20px;
            margin-top: 20px;
        }
    }
}