.home-page {
    .text-zone {
        position: absolute;
        left: 11%;
        top: 50%;
        transform: translateY(-50%);
        width: 45%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 70px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &:before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            position: absolute;
            margin-top: -30px;
            left: 15px;
            opacity: 0.6;
            letter-spacing: 3px;
            animation: fadeIn 5s 1.7s backwards;
        }

        &:after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 5s 1.7s backwards;
            opacity: 0.6;
            letter-spacing: 3px;
        }

        img {
            width: 64px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: bounceIn 1s 1s both;
            animation-fill-mode: forwards;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 24px;
        font-family: 'sans-serif';
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: 'sans-serif';
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }
}

.home-buttons {
    a {
        width: fit-content;
    }
}

@media screen and (max-width: 1260px) {
    .home-page {
        h1 {
            font-size: 55px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .tags, 
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }
    
    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        .home-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5%;
        }

        .flat-button {
            font-size: 12px;
            display: block;
            width: 125px;
            top: 50px;
            margin: 0;
        }
    }
}

@media screen and (max-width: 470px) {
    .home-page {
        h1 {
            font-size: 40px;

            img {
                width: 40px;
                margin-left: 20px;
                opacity: 0;
                height: auto;
                animation: bounceIn 1s 1s both;
                animation-fill-mode: forwards;
                animation-delay: 1.4s;
            }
        }

        h2 {
            font-size: 16px;
        }
    }
}