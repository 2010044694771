.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    top: 2px;
}

.bottom-tags {
    height: 30px;
    top: auto;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: fixed;
    bottom: 0;
    left: 130px;
    font-size: 12px;
    font-family: 'La Belle Aurore', cursive;
    letter-spacing: 3px;
    animation: fadeIn 5s 1.7s backwards;
}

.botton-tag-html {
    margin-left: -25px;
}

.top-tag-html {
    margin-left: -25px;
}


.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 40px;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    overflow: auto;
}

.container.contact-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 37%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
    }
    
    h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left: 20px;

        &:before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            opacity: 0.6;
            line-height: 20px;
            letter-spacing: 3px;
            animation: fadeIn 5s 1.7s backwards;
        }

        &:after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            line-height: 20px;
            position: absolute;
            left: -30px;
            bottom: -20px;
            margin-left: 20px;
            opacity: 0.6;
            letter-spacing: 3px;
            animation: fadeIn 5s 1.7s backwards;
        }
    }

    p {
        font-size: 20px;
        color: #fff;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;

        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
            animation-delay: 1.3s;
        }

        &:nth-of-type(3) {
            animation-delay: 1.5s;
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

.container.about-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 45%;
        vertical-align: middle;
        max-height: 90%;
    }
    
    h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left: 20px;

        &:before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            opacity: 0.6;
            line-height: 20px;
            letter-spacing: 3px;
            animation: fadeIn 5s 1.7s backwards;
        }

        &:after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 20px;
            line-height: 20px;
            position: absolute;
            left: -30px;
            bottom: -20px;
            margin-left: 20px;
            opacity: 0.6;
            letter-spacing: 3px;
            animation: fadeIn 5s 1.7s backwards;
        }
    }

    p {
        font-size: 20px;
        color: #fff;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;

        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
            animation-delay: 1.3s;
        }

        &:nth-of-type(3) {
            animation-delay: 1.5s;
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

@media screen and (max-height: 650px) {
    .tags {
        display: none;
    }

    .container {
        height: 100%;
        top: 2px;
    }
}

@media screen and (max-width: 1200px) {
    .page {
        position: initial;
    }

    .container {
        position: initial;
        height: auto;
        min-height: auto;
        height: 100%;

        &.about-page, 
        &.contact-page {
            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
}