.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    position: absolute;
    top: 0;
    right: 15%;
    left: auto;
    margin: auto;
    opacity: 0;

    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(30deg) !important;
    }

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        transform: rotateZ(30deg);
        z-index: 1;
    }

    .svg-container {
        stroke: #ffd700;
        stroke-width: 10;
    }
}

.lottie-container {
    z-index: 0;
    width: 32%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 60%;
    margin: auto;
    
}

@media screen and (max-width: 1200px) {
    .lottie-container {
        position: relative;
        height: auto;
        right: 0;
        left: 0;
        margin: auto;
        width: 60%;
    }
}

@media screen and (max-width: 470px) {
    .lottie-container {
        position: relative;
        height: auto;
        right: 0;
        left: 0;
        margin: auto;
        width: 80%;
        top: 40px;
    }
}
