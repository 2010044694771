.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.photo-cont {
    img {
        position: absolute;
        top: 60%;
        left: 60%;
        width: auto;
        transform: translateY(-50%);
        vertical-align: middle;
        display: flex;
        max-height: 90%;
        
        opacity: 0;
        animation: fadeIn 4s forwards;
        animation-delay: 1.5s;
    }
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        font-size: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
        transform: translateZ(100px);
    }
    .face2 {
        transform: rotateY(90deg) rotateZ(-90deg) translateZ(100px);
    }
    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    }
    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
    }
}

@keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateX(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }

.flat-button {
    color: #ffd700;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: 'sans-serif';
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-bottom: 5%;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;
    display: block;
    text-align: center;
    width: fit-content;
    min-width: fit-content;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        background: #ffd700;
        color: #333;
    }
}

@media screen and (max-width: 1200px) {
    .photo-cont {
        img {
            position: initial;
            transform: none;
            width: 100%;
            display: block;
            padding: 20px;
            box-sizing: border-box;
        }
    }

    .flat-button {
        float: left;
        font-size: 18px;
        display: block;
        width: 125px;
        margin: 20px auto 20px auto;
        top: 50px;
        float: none;
    }
}